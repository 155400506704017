<div class="modal-header">
  <div class="modal-header-x" (click)="bsModalRef.hide()">
    <i-feather name="x"></i-feather>
  </div>
</div>
<div class="access-group-modal-container">
  <header class="border-bottom p-2">
    <h1 class="fs-17px font-weight-bold">Create Group Chat</h1>
  </header>
  <form class="p-2" (submit)="createGroupChat($event)">
    <div class="form-group">
      <label for="accessGroupName">Group Name</label>
      <input type="text" id="accessGroupName" name="accessGroupName" class="form-control" [(ngModel)]="groupName" />
    </div>
    <div class="form-group" [ngStyle]="{ height: '72px' }">
      <label for="accessGroupMembers">Add Group Members</label>
      <search-bar
        [showCloutavista]="false"
        [isSearchForUsersToMessage]="true"
        [startingSearchText]=""
        (creatorToMessage)="onSearchItemSelected($event)"
        class="position-relative"
      ></search-bar>
    </div>
    <div *ngIf="groupMembers.length > 0">
      <div *ngFor="let member of groupMembers" class="d-flex align-items-center border p-2 mb-2">
        <div
          class="messages-thread__avatar"
          [ngClass]="{
            nft__avatar: member?.ExtraData?.NFTProfilePictureUrl
          }"
          [avatar]="member.PublicKeyBase58Check"
          [nftProfileUrl]="member?.ExtraData?.NFTProfilePictureUrl"
        ></div>
        <span class="ml-3 fs-15px font-weight-bold">{{ member.Username || member.PublicKeyBase58Check }}</span>
        <div class="ml-auto">
          <button
            aria-label="Remove group member"
            class="btn fc-red"
            (click)="removeGroupMember(member.PublicKeyBase58Check)"
          >
            <i-feather name="trash-2"></i-feather>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <button class="btn btn-secondary ml-auto mr-2" (click)="bsModalRef.hide()">Cancel</button>
      <button class="btn btn-primary">
        <span *ngIf="isCreatingGroup"><i class="fas fa-spinner fa-spin ml-5px"></i></span>
        <span *ngIf="!isCreatingGroup">Create</span>
      </button>
    </div>
  </form>
</div>
