<div class="modal-header">
  <div class="modal-header-x" (click)="bsModalRef.hide()">
    <i-feather name="x"></i-feather>
  </div>
</div>
<transfer-deso
  (closeModal)="bsModalRef.hide()"
  [isModal]="true"
  [creatorToPayInput]="creatorToPayInput"
></transfer-deso>
