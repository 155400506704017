<div app-theme class="nft-modal-container p-15px">
  <nft-modal-header [header]="'Bid placed'" [bsModalRef]="bsModalRef"></nft-modal-header>

  <div class="pb-15px fs-15px">
    {{ "bid_placed_modal.bid_successful" | transloco }}
  </div>

  <div
    class="font-weight-500 fs-15px pb-15px cursor-pointer"
    style="text-decoration-line: underline"
    (click)="globalVars.exploreShowcase(bsModalRef, modalService)"
  >
    {{ "bid_placed_modal.explore_showcase" | transloco }}
  </div>
</div>
