<div *ngIf="readyForDisplay(); else loadingElseBlock">
  <div *ngIf="this.appData.loggedInUser; else elseBlock">
    <div class="buy-deso__container fc-secondary">
      <div class="px-16px" *ngIf="screenToShow !== this.TRADE_CREATOR_PREVIEW_SCREEN">
        <div class="d-flex">
          <a
            [ngClass]="{
              nft__avatar: creatorCoinTrade?.creatorProfile?.ExtraData?.NFTProfilePictureUrl
            }"
            class="d-none d-lg-block creator-profile__avatar mr-15px"
            [avatar]="creatorCoinTrade.creatorProfile.PublicKeyBase58Check"
            [nftProfileUrl]="creatorCoinTrade.creatorProfile?.ExtraData?.NFTProfilePictureUrl"
          ></a>
          <div class="d-flex flex-column justify-content-center fc-default">
            <div class="fs-24px">
              {{ globalVars.addOwnershipApostrophe(creatorCoinTrade.creatorProfile.Username) }} coin
            </div>
            <div class="fs-17px">
              <span>≈{{ globalVars.nanosToUSD(creatorCoinTrade.creatorProfile.CoinPriceDeSoNanos, 2) }}&nbsp;</span>
              <span class="fc-muted">Coin Price</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Tabs -->
      <tab-selector
        [buttonSelector]="false"
        [tabs]="tabList"
        [activeTab]="creatorCoinTrade.tradeType"
        (tabClick)="_handleTabClick($event)"
        *ngIf="!inTutorial && screenToShow !== this.TRADE_CREATOR_PREVIEW_SCREEN"
      ></tab-selector>

      <!--Preview Header-->
      <div
        *ngIf="screenToShow === this.TRADE_CREATOR_PREVIEW_SCREEN && !inTutorial"
        class="mt-5px m-16px mint-nft__title"
      >
        Confirm Operation
      </div>

      <!--Preview Header-->
      <div *ngIf="screenToShow === this.TRADE_CREATOR_PREVIEW_SCREEN && inTutorial" class="p-16px">
        <div class="d-flex align-items-center">
          <div
            [ngClass]="{
              nft__avatar: creatorCoinTrade?.creatorProfile?.ExtraData?.NFTProfilePictureUrl
            }"
            class="simple-profile-card__avatar br-12px"
            [avatar]="creatorCoinTrade.creatorProfile.PublicKeyBase58Check"
            [nftProfileUrl]="creatorCoinTrade.creatorProfile?.ExtraData?.NFTProfilePictureUrl"
          ></div>
          <div class="ml-16px fs-20px font-weight-bold">
            {{ creatorCoinTrade.tradeType }} {{ creatorCoinTrade.creatorProfile.Username }} Coin
          </div>
        </div>
      </div>

      <!-- Content -->
      <div *ngIf="screenToShow === this.TRADE_CREATOR_FORM_SCREEN">
        <trade-creator-form
          [inTutorial]="inTutorial"
          [creatorCoinTrade]="creatorCoinTrade"
          [hideWarning]="hideWarning"
          (previewClicked)="_onPreviewClicked()"
          (buyDesoClicked)="openBuyDeSoModal()"
          (closeModal)="hideModal.emit()"
        ></trade-creator-form>
      </div>

      <div *ngIf="screenToShow === this.TRADE_CREATOR_PREVIEW_SCREEN">
        <trade-creator-preview
          [inTutorial]="inTutorial"
          [creatorCoinTrade]="creatorCoinTrade"
          [buyButtonDisabled]="buyButtonDisabled"
          (slippageError)="_onSlippageError()"
          (backButtonClicked)="_onBackButtonClicked()"
          (tradeExecuted)="_onTradeExecuted()"
        ></trade-creator-preview>
      </div>

      <div *ngIf="screenToShow === this.TRADE_CREATOR_COMPLETE_SCREEN">
        <trade-creator-complete
          [creatorCoinTrade]="creatorCoinTrade"
          (tradeAgainButtonClicked)="_onBackButtonClicked()"
          (viewWalletClicked)="hideModal.emit()"
        ></trade-creator-complete>
      </div>
    </div>
  </div>

  <ng-template #elseBlock>
    <trade-creator-logged-out [creatorCoinTrade]="creatorCoinTrade"></trade-creator-logged-out>
  </ng-template>
</div>
<ng-template #loadingElseBlock>
  <simple-center-loader></simple-center-loader>
</ng-template>
