<div class="reposts-modal-container">
  <div *ngIf="!globalVars.isMobile()" class="w-100 d-flex align-items-center border-bottom fc-secondary">
    <div class="p-15px fs-25px font-weight-bold d-flex align-items-center">
      <div class="fs-17px font-weight-bold">Reposts</div>
    </div>
  </div>

  <div class="flex-grow-1">
    <div [ngClass]="{ 'details-modal__list-wrapper': !globalVars.isMobile() }">
      <div #uiScroll *uiScroll="let profile of datasource">
        <simple-profile-card
          [profile]="profile"
          [followButtonOppositeSide]="true"
          [hideFollowLink]="true"
          [containerModalRef]="globalVars.isMobile() ? null : bsModalRef"
        ></simple-profile-card>
      </div>
    </div>
  </div>
  <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
  <div *ngIf="globalVars.isMobile()" class="global__bottom-bar-mobile-height"></div>
  <simple-center-loader
    *ngIf="datasource.adapter.isLoading && datasource.adapter.itemsCount === 0"
    class="x-and-y-centerized"
  ></simple-center-loader>
</div>
