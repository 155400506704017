<div class="reactions-modal-container">
  <div *ngIf="!globalVars.isMobile()" class="w-100 d-flex align-items-center border-bottom fc-secondary">
    <div class="p-15px fs-25px font-weight-bold d-flex align-items-center">
      <div class="fs-17px font-weight-bold">Reacted By</div>
    </div>
  </div>

  <div class="flex-grow-1">
    <alert *ngIf="post && post.LikeCount > 0" type="info">
      <strong>{{ post.LikeCount }} legacy likes</strong>
      were hidden from the summary
    </alert>

    <tab-selector
      [tabs]="reactionTabs"
      [activeTab]="activeReactionTab"
      [highlightTab]="true"
      (tabClick)="selectTab($event)"
    >
      <ng-template let-tabItem #tabItem>
        <div>
          <reactions-image [reaction]="tabItem" [size]="20"></reactions-image>
          <b class="ml-2">{{ postReactionCounts.Counts[tabItem] }}</b>
        </div>
      </ng-template>
    </tab-selector>

    <div *ngIf="usersReacted.length > 0" [ngClass]="{ 'details-modal__list-wrapper': !globalVars.isMobile() }">
      <div #uiScroll *uiScroll="let user of datasource">
        <simple-profile-card
          [profile]="user.profile"
          [pubKeyBase58Check]="user.publicKey"
          [followButtonOppositeSide]="true"
          [hideFollowLink]="true"
          [reaction]="activeReactionTab"
          [containerModalRef]="globalVars.isMobile() ? null : bsModalRef"
        ></simple-profile-card>
      </div>
    </div>
  </div>
  <!-- Spacer for PWA Apps -->
  <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
  <div *ngIf="globalVars.isMobile()" class="global__bottom-bar-mobile-height"></div>
  <simple-center-loader *ngIf="loading"></simple-center-loader>
</div>
