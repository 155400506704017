<div *ngIf="!globalVars.loggedInUser" class="d-flex align-items-center justify-content-end">
  <div
    class="fs-15px fc-blue font-weight-bold cursor-pointer px-15px d-flex align-items-center h-100"
    (click)="globalVars.launchLoginFlow('change-account-selector-login-button')"
  >
    <span style="white-space: nowrap">{{ "change_account.login" | transloco }}</span>
  </div>
  <a
    (click)="globalVars.launchLoginFlow('change-account-selector-signup-button')"
    class="btn btn-primary font-weight-bold fs-15px d-flex justify-content-center align-items-center br-12px"
    style="height: 36px; width: 90px; line-height: 15px"
  >
    {{ "change_account.signup" | transloco }}
  </a>
</div>
<div class="btn-group" dropdown #dropdown="bs-dropdown" style="width: 100%" *ngIf="globalVars.loggedInUser">
  <div
    class="fs-14px fc-default px-5px cursor-pointer change-account-selector br-4px change-account-selector__hover"
    [ngClass]="{ 'change-account-selector__shadow': selectorOpen }"
    dropdownToggle
    id="changeAccountButton"
    aria-controls="dropdown-basic"
  >
    <!-- Selector (unopened state) -->
    <div class="d-flex align-items-center justify-content-between pl-5px pr-5px">
      <div
        *ngIf="globalVars.loggedInUser"
        class="change-account-selector__ellipsis-restriction d-flex flex-row align-items-center cursor-pointer"
      >
        <div
          [ngClass]="{
            nft__avatar: globalVars.loggedInUser?.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl
          }"
          class="feed-create-post__avatar align-items-center change-account-selector__account-image"
          [avatar]="globalVars.loggedInUser.PublicKeyBase58Check"
          [nftProfileUrl]="globalVars.loggedInUser?.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl"
        ></div>
        <div class="change-account-selector__acount-name change-account-selector__ellipsis-restriction">
          {{
            globalVars.loggedInUser.ProfileEntryResponse?.Username
              ? globalVars.loggedInUser.ProfileEntryResponse.Username
              : globalVars.loggedInUser.PublicKeyBase58Check
          }}
        </div>
        <span
          *ngIf="globalVars.loggedInUser.ProfileEntryResponse?.IsVerified"
          class="ml-1 d-inline-block align-center text-primary fs-12px"
        >
          <i class="fas fa-check-circle fa-md align-middle"></i>
        </span>
      </div>
      <i class="fas fa-angle-down text-grey5 change-account-selector__down-arrow"></i>
    </div>
  </div>
  <!-- Drop-down (open state) -->
  <div
    class="fs-14px fc-default px-5px cursor-pointer change-account-selector_list br-4px change-account-selector_list__hover dropdown-menu"
    id="dropdown-basic"
    *dropdownMenu
    aria-labelledby="changeAccountButton"
  >
    <div class="change-account-selector_list_accounts">
      <div *ngFor="let user of globalVars.userList; let rowNum = index">
        <div
          *ngIf="!globalVars.loggedInUser || user.PublicKeyBase58Check !== globalVars.loggedInUser.PublicKeyBase58Check"
          (click)="_switchToUser(user)"
          class="change-account-selector_list__inner pt-10px pb-10px pl-10px pr-10px d-flex align-items-center"
        >
          <div class="align-items-center change-account-selector_list-item d-flex flex-row" style="flex-grow: 1">
            <div
              [ngClass]="{
                nft__avatar: user.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl
              }"
              class="feed-create-post__avatar d-inline-block change-account-selector__account-image"
              [avatar]="user.PublicKeyBase58Check"
              [nftProfileUrl]="user.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl"
            ></div>
            <div
              class="d-inline-block change-account-selector__ellipsis-restriction change-account-selector__acount-name"
            >
              {{ user.ProfileEntryResponse?.Username ? user.ProfileEntryResponse.Username : user.PublicKeyBase58Check }}
            </div>
            <span
              *ngIf="user.ProfileEntryResponse?.IsVerified"
              class="ml-1 d-inline-block align-center text-primary fs-12px"
            >
              <i class="fas fa-check-circle fa-md align-middle"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div (click)="globalVars.launchLoginFlow('add-account-button')" class="pl-15px text-link_hover pr-10px pt-10px">
      {{ "change_account.add_account" | transloco }}
    </div>
    <div *ngIf="globalVars.loggedInUser" class="pl-15px text-link_hover pr-10px pt-10px" (click)="launchLogoutFlow()">
      {{ "change_account.logout" | transloco }}
    </div>
  </div>
</div>
