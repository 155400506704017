<div class="buy-deso__container">
  <div class="mint-nft__title ml-16px">Buy $DESO</div>
  <div class="w-100 border-bottom"></div>
  <div class="global__mobile-scrollable-section">
    <!-- Description, Logged Out -->
    <div class="fc-default ml-5px fs-15px mt-30px pr-15px pb-15px">
      {{ "buy_deso.deso_explanation" | transloco }}
    </div>

    <!-- Spacer -->
    <div style="height: 10px"></div>

    <!-- Logged Out Info -->
    <div class="fs-16px mint-nft__title ml-5px">{{ "buy_deso.how_to_get" | transloco }}</div>
    <div class="w-100 border-bottom"></div>
    <div class="d-flex w-100 mt-30px fs-15px ml-5px pb-15px pr-30px">
      <div style="min-width: 120px">
        <a (click)="globalVars.launchLoginFlow('buy-deso-logged-out-signup-button')" class="--link">
          {{ "buy_deso.create_anon_account" | transloco }}
        </a>
        {{ "buy_deso.to_buy_deso_fast" | transloco }}
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="fs-16px ml-5px mint-nft__title" style="background-color: white">
      {{ "buy_deso.what_is_deso_for" | transloco }}
    </div>
    <div class="w-100 border-bottom"></div>
    <div class="ml-5px">
      <div class="d-flex w-100 pt-15px pb-15px fs-15px">
        <div style="min-width: 120px">
          {{ "buy_deso.use_to_buysell_creatorcoins_0" | transloco }}
          <a
            [routerLink]="['/' + globalVars.RouteNames.CREATORS]"
            [routerLinkActive]="['selected']"
            [routerLinkActiveOptions]="{ exact: true }"
            queryParamsHandling="merge"
          >
            {{ "buy_deso.creatorcoins" | transloco }}
          </a>
          {{ "buy_deso.use_to_buysell_creatorcoins_1" | transloco }}
        </div>
      </div>
      <div class="d-flex w-100 justify-content-between pr-15px pb-15px mt-15px fs-15px fc-default">
        {{ "buy_deso.use_to_send_messages" | transloco }}
      </div>
      <div class="d-flex w-100 justify-content-between pr-15px pt-15px pb-15px mb-15px fs-15px fc-default">
        <div>
          {{ "buy_deso.use_to_post_0" | transloco }}
          <a
            [routerLink]="['/' + globalVars.RouteNames.BROWSE]"
            [routerLinkActive]="['selected']"
            [routerLinkActiveOptions]="{ exact: true }"
            queryParamsHandling="merge"
          >
            {{ "buy_deso.submit_posts" | transloco }}
          </a>
          {{ "buy_deso.use_to_post_1" | transloco }}
        </div>
      </div>
      <div class="d-flex w-100 justify-content-between pr-15px pb-15px fs-15px fc-default">
        {{ "buy_deso.buy_with_bc_anon" | transloco }}
      </div>
      <div class="d-flex w-100 justify-content-between pr-15px pt-15px pb-15px fs-15px fc-default">
        <div>{{ "buy_deso.blockchains_iphone_moment" | transloco }}</div>
      </div>
    </div>

    <!-- SPACER FOR BOTTOM BAR ON MOBILE -->
    <div class="global__bottom-bar-mobile-height"></div>
    <div class="global__bottom-bar-mobile-height"></div>
  </div>
</div>
