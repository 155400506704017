<span *ngIf="!hideMessage" class="m-0px">{{ globalVars.getFreeDESOMessage() }}</span>
<sup style="padding-left: 1px">
  <i
    (click)="$event.stopPropagation(); tooltip1.show()"
    class="fas fa-info-circle global__tooltip-icon"
    matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
    matTooltip="Amount may vary based on locality of ID"
    #tooltip1="matTooltip"
  ></i>
</sup>
