import { UrlSegment, Route, UrlSegmentGroup, UrlMatchResult } from '@angular/router';
import { CreatorProfilePageComponent } from "./creator-profile-page/creator-profile-page.component";

export function subdomainMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null {
    const hostname = window.location.hostname;
    const subdomains = hostname.split('.');
    const hasSubdomain = subdomains.length > 2;
  
    if (hasSubdomain && route.data && route.data.subdomain === true) {
      const username = subdomains[0];
      const isUserProfileRoute = route.component === CreatorProfilePageComponent;
  
      if (isUserProfileRoute && segments.length === 0) {
        return {
          consumed: segments,
          posParams: { username: new UrlSegment(username, {}) },
        };
      } else {
        return null;
      }
    } else if (!hasSubdomain && route.data && route.data.subdomain === false) {
      return {
        consumed: segments,
        posParams: {},
      };
    }
  
    return null;
  }