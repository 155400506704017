<!-- Override .container's max-width property with max-width: inherit-->
<div class="container fs-15px" style="max-width: inherit">
  <trade-creator-table [creatorCoinTrade]="creatorCoinTrade"></trade-creator-table>

  <!-- Buttons -->
  <div class="row no-gutters pt-3">
    <div class="d-flex justify-content-end w-100">
      <button class="plain-button mr-10px" (click)="this._buyMoreClicked()">Back</button>
      <button
        class="primary-button"
        [routerLink]="'/' + this.appData.RouteNames.WALLET"
        (click)="viewWalletClicked.emit()"
        queryParamsHandling="merge"
      >
        View Wallet
      </button>
    </div>
  </div>

  <!--Spacer (for mobile)-->
  <div class="pb-25px"></div>
</div>
