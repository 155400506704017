<div
  class="w-100 d-flex flex-column right-bar-creators__wallet-container"
  *ngIf="this.globalVars?.loggedInUser?.ProfileEntryResponse?.CoinEntry"
>
  <div>
    <div class="font-weight-bold fc-default">
      {{ "wallet_widget.wallet_of" | transloco }} &#64;{{ globalVars?.loggedInUser?.ProfileEntryResponse?.Username }}
    </div>
    <div class="d-flex justify-content-between align-items-center mt-16px">
      <span>{{ "wallet_widget.coins_in_circulation" | transloco }}</span>
      <span>
        ≈ {{ (globalVars?.loggedInUser?.ProfileEntryResponse?.CoinEntry.CoinsInCirculationNanos / 1e9).toFixed(2) }}
      </span>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-16px">
      <span>{{ "wallet_widget.total_usd_locked" | transloco }}</span>
      <span>≈ {{ totalUSDLocked() }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-16px">
      <span>{{ "wallet_widget.usd_market_cap" | transloco }}</span>
      <span>≈ {{ usdMarketCap() }}</span>
    </div>
  </div>
</div>
