<!-- These `canvas` tags are used by this.globalVars.celebrate() function to trigger confetti animations. -->
<!-- We use 5 different elements to be able to run 5 animations simultaneously. -->
<canvas id="my-canvas-0"></canvas>
<canvas id="my-canvas-1"></canvas>
<canvas id="my-canvas-2"></canvas>
<canvas id="my-canvas-3"></canvas>
<canvas id="my-canvas-4"></canvas>

<div
  class="main global__container bg-tile"
  [ngClass]="globalVars.userIsDragging ? 'unselectable' : ''"
  *ngIf="!globalVars.requestingStorageAccess"
  (touchmove)="preventScroll($event)"
>
  <install-pwa *ngIf="globalVars.isMobile() && !globalVars.windowIsPWA() && globalVars.showInstallPWA"></install-pwa>

    <router-outlet *ngIf="!globalVars.loadingInitialAppState"></router-outlet>
</div>
