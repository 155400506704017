import { Component } from "@angular/core";

@Component({
  selector: "manage-follows-page",
  templateUrl: "./manage-follows-page.component.html",
  styleUrls: ["./manage-follows-page.component.scss"],
})
export class ManageFollowsPageComponent {
  constructor() {}
}
