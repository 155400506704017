<div class="modal-header">
  <div class="modal-header-x" (click)="bsModalRef.hide()">
    <i-feather name="x"></i-feather>
  </div>
</div>
<div app-theme class="nft-modal-container">
  <div class="nft-modal__header m-16px mb-8px">Do you want to close your auction?</div>
  <div class="mb-16px mx-16px fs-15px fc-muted">
    You are going to close your auction. It will cancel all bids on your NFT.
  </div>
  <div class="w-100 border-bottom"></div>
  <div class="d-flex align-items-center">
    <button class="w-100 danger-button fs-15px br-12px m-16px" (click)="closeAuction()" [disabled]="closingAuction">
      {{ closingAuction ? "Closing auctions" : "Close my auctions" }}
    </button>
    <div *ngIf="closingAuction" class="pl-15px fs-15px">{{ auctionCounter }} of {{ auctionTotal }} closed</div>
  </div>
</div>
