import { Component } from "@angular/core";

@Component({
  selector: "app-tos-page",
  templateUrl: "./tos-page.component.html",
  styleUrls: ["./tos-page.component.scss"],
})
export class TosPageComponent {
  constructor() {}
}
