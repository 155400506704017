<app-page hideSidebar="true">
<div class="flex-grow-1">
  <div class="buy-deso__container">
    <div class="px-16px pb-16px">
      <div class="mint-nft__title">{{ "creators_leaderboard.buy_coin" | transloco }}</div>
      <div class="mint-nft__sub-text">{{ "creators_leaderboard.buy_coin_subtitle" | transloco }}</div>
    </div>
    <div>
      <!-- Headers -->
      <div class="fs-15px">
        <div
          class="row px-15px no-gutters border-bottom py-15px justify-content-end fc-muted"
          style="margin-left: 0; margin-right: 0"
        >
          <div class="col-lg-5 col-8 mb-0">{{ "creators_leaderboard.name" | transloco }}</div>
          <div class="col-lg-7 col-4 mb-0 text-left" style="min-width: 100px">
            <div class="pl-15px">{{ "creators_leaderboard.price" | transloco }}</div>
          </div>
        </div>
      </div>

      <simple-center-loader *ngIf="isLoadingProfilesForFirstTime"></simple-center-loader>

      <div class="viewport disable-scrollbars" [ngClass]="{ 'buy-creator-scroll-container': !isModal }">
        <!-- Creator rows -->
        <div #uiScroll *uiScroll="let item of datasource" class="row no-gutters px-15px border-bottom fs-15px">
          <a
            class="col-lg-6 col-8 mb-0 py-15px link--unstyled d-flex align-items-center"
            [routerLink]="['/' + globalVars.RouteNames.USER_PREFIX, item.Username]"
            queryParamsHandling="merge"
          >
            <div
              [ngClass]="{
                nft__avatar: item?.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl
              }"
              class="creators-leaderboard__avatar d-inline-block align-top"
              [avatar]="item.PublicKeyBase58Check"
              [nftProfileUrl]="item?.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl"
            ></div>
            <a class="d-inline-block ml-3 fc-default">{{ item.Username }}</a>

            <span
              *ngIf="item.IsReserved && !item.IsVerified"
              class="d-inline-block ml-10px cursor-pointer fs-18px lh-12px fc-muted"
              matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
              [matTooltip]="'This profile is reserved. Click to learn how to claim it.'"
            >
              <i class="far fa-clock fa-md align-middle"></i>
            </span>

            <span
              *ngIf="item.IsVerified"
              (click)="tooltip.toggle()"
              class="ml-10px cursor-pointer fs-20px lh-12px text-primary"
              matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
              [matTooltip]="'This account is verified'"
              #tooltip="matTooltip"
            >
              <i-feather name="check-circle" class="fs-0px feather-medium"></i-feather>
            </span>
          </a>
          <div class="col-lg-6 col-4 d-flex align-items-center justify-content-end mb-0px">
            <a
              class="col-1 mb-0 py-15px link--unstyled text-left"
              [ngClass]="{ 'mr-30px': globalVars.isMobile() }"
              [ngStyle]="{ 'min-width': globalVars.isMobile() ? '0px' : '100px' }"
              [routerLink]="['/' + globalVars.RouteNames.USER_PREFIX, item.Username]"
              queryParamsHandling="merge"
            >
              <div>~{{ globalVars.nanosToUSD(item.CoinPriceDeSoNanos, globalVars.isMobile() ? 0 : 2) }}</div>
            </a>

            <!--Hide on mobile-->
            <div class="py-15px d-lg-block mb-0 d-flex col-9">
              <div class="pl-15px d-flex align-items-center justify-content-end">
                <div *ngIf="appData.loggedInUser && !globalVars.isMobile()" class="d-inline-block text-right">
                  <div class="mr-3 align-top">
                    <div *ngIf="canLoggedInUserFollowTargetPublicKey(item.PublicKeyBase58Check)">
                      <follow-button
                        [displayAsLink]="true"
                        [followedPubKeyBase58Check]="item.PublicKeyBase58Check"
                      ></follow-button>
                    </div>
                    <div *ngIf="!canLoggedInUserFollowTargetPublicKey(item.PublicKeyBase58Check)"></div>
                  </div>
                </div>
                <button
                  class="primary-button"
                  (click)="openBuyCreatorCoinModal($event, item.Username)"
                  [ngStyle]="{ width: globalVars.isMobile() ? '60px' : '78px' }"
                >
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Spacer for PWA Apps -->
      <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
      <div *ngIf="globalVars.isMobile()" class="global__bottom-bar-mobile-height"></div>
    </div>
  </div>
</div>
</app-page>