<!-- Blog Posts -->
<simple-center-loader size="m" *ngIf="loadingFirstPage"></simple-center-loader>
<div *ngIf="!showProfileAsReserved && !loadingFirstPage && blogPosts.length === 0" class="p-15px">
  <div class="d-flex flex-row align-items-center" style="text-align: center">
    <a
      *ngIf="profileBelongsToLoggedInUser(); else elseMissingPostBlock"
      [routerLink]="['/' + globalVars.RouteNames.CREATE_LONG_POST]"
    >
      {{ "creator_profile_blog_posts.create_fipo" | transloco }}
    </a>
    <ng-template #elseMissingPostBlock>
      <span>&#64;{{ profile.Username }} {{ "creator_profile_posts.on_platform_no_posts" | transloco }}</span>
    </ng-template>
  </div>
</div>
<ng-container *ngIf="!showProfileAsReserved">
  <div *ngIf="!globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)">
    <div *ngFor="let post of blogPosts; let index = index">
      <feed-post
        *ngIf="post.ProfileEntryResponse"
        [showLeftSelectedBorder]="post?.PostExtraData?.BlogPostIsPinned === 'true'"
        [contentShouldLinkToThread]="true"
        [includePaddingOnPost]="true"
        [post]="post"
        [afterCommentCreatedCallback]="_prependComment.bind(this, post, index)"
        [blocked]="globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)"
        [cardStyle]="true"
        [showNFTDetails]="true"
        [videoPaused]="pauseVideos"
        (userBlocked)="userBlocked()"
        (pauseAllVideos)="pauseAllVideos($event)"
        (toggleBlogPin)="updatePinnedPosts($event)"
      ></feed-post>
    </div>
    <!-- Spacer for PWA Apps -->
    <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
    <div *ngIf="globalVars.isMobile()" class="global__bottom-bar-mobile-height"></div>
  </div>
</ng-container>
