<div
  class="global__top-bar global__top-bar__height d-flex align-items-center fs-18px font-weight-bold pl-15px border-bottom border-color-grey"
>
  <top-bar-mobile-navigation-control class="mr-15px d-lg-none d-inline-block"></top-bar-mobile-navigation-control>
  {{ "follow_button.loading" | transloco }}
  Get Starter $DESO
</div>

<div class="global__top-bar__height"></div>

<div class="p-15px">
  <sign-up-get-starter-deso></sign-up-get-starter-deso>
</div>
