<div class="buy-deso__container">
  <div class="p-16px">
    <div class="mint-nft__title">
      {{ "transfer_deso.send_deso" | transloco }}
    </div>
    <div class="mint-nft__sub-text">
      {{ "transfer_deso.enter_public_key" | transloco }}
    </div>
    <div class="mint-nft__sub-title">
      {{ "transfer_deso.public_key" | transloco }}
    </div>
    <!-- Search Bar -->
    <search-bar
      [showCloutavista]="false"
      [startingSearchText]="startingSearchText"
      [isSearchForUsersToSendDESO]="true"
      [resultsUnderBar]="true"
      (creatorToMessage)="_handleCreatorSelectedInSearch($event)"
    ></search-bar>

    <simple-profile-card
      [profile]="payToCreator"
      [singleColumn]="true"
      [hideFollowLink]="true"
      *ngIf="payToCreator"
    ></simple-profile-card>
  </div>
  <div class="w-100 border-bottom" *ngIf="payToCreator"></div>
  <div class="p-16px">
    <div class="mint-nft__sub-title" [ngStyle]="{ 'margin-top': payToCreator ? '16px' : '30px' }">
      {{ "transfer_deso.amount_to_send" | transloco }}
    </div>
    <div class="d-flex">
      <div class="d-flex" container="body" style="align-items: center">
        <div class="mint-nft__dropdown-btn">$DESO</div>
      </div>
      <div class="position-relative w-100">
        <input
          [(ngModel)]="transferAmount"
          style="border-radius: 0px 6px 6px 0px"
          aria-describedby="usd-label"
          class="mint-nft__input"
          type="number"
          min="0"
          placeholder="0"
        />
        <span class="mint-nft__floating-span">
          <a class="fc-blue" (click)="_clickMaxDeSo()">{{ "transfer_deso.max" | transloco }}</a>
        </span>
      </div>
    </div>
    <div class="mt-16px fc-muted">
      <i-feather name="info" class="feather-small position-relative mr-10px" style="top: -2px"></i-feather>
      <span class="mint-nft__sub-text">{{ "transfer_deso.deso_network_fee" | transloco }}</span>
      <span class="mt-1 w-100 fs-15px ml-10px">
        <span>
          {{ globalVars.nanosToDeSo(networkFee * 1e9) }}
          (≈ {{ globalVars.nanosToUSD(networkFee * 1e9) }} USD)
        </span>
      </span>
    </div>
    <div class="mt-16px fc-muted">
      <i-feather name="info" class="feather-small position-relative mr-10px" style="top: -2px"></i-feather>
      <span class="mint-nft__sub-text">{{ "transfer_deso.total" | transloco }}</span>
      <span class="mt-1 w-100 fs-15px ml-10px">
        <span>
          {{ globalVars.nanosToDeSo((transferAmount + networkFee) * 1e9) }}
          (≈ {{ globalVars.nanosToUSD((transferAmount + networkFee) * 1e9) }} USD)
        </span>
      </span>
    </div>
    <div *ngIf="transferDeSoError !== ''" class="error-container" style="white-space: pre-line">
      <i-feather name="alert-circle" class="feather-large"></i-feather>
      {{ transferDeSoError }}
    </div>
  </div>
  <div class="w-100 border-bottom"></div>
  <div class="p-16px">
    <button
      class="primary-button"
      (click)="_clickSendDeSo()"
      [disabled]="sendingDeSo"
      [ngClass]="{ 'disabled-button': sendingDeSo }"
    >
      {{ (!sendingDeSo ? "transfer_deso.send_deso" : "transfer_deso.working") | transloco }}
    </button>
    <a *ngIf="isModal" class="fc-muted ml-25px" (click)="closeModal.emit()">{{ "transfer_deso.cancel" | transloco }}</a>
  </div>
  <div class="w-100 border-bottom"></div>
  <div class="p-15px d-flex flex-column align-items-center">
    <p>
      {{ "transfer_deso.scan_qr_code" | transloco }} {{ globalVars.loggedInUser.ProfileEntryResponse?.Username }} $DESO
    </p>
    <img [src]="sendDeSoQRCode | sanitizeQRCode" />
  </div>
</div>

<!-- Bottom spacer for PWA apps-->
<div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
<!-- SPACER FOR BOTTOM BAR ON MOBILE -->
<div class="global__bottom-bar-mobile-height"></div>
