import { Component } from "@angular/core";

@Component({
  selector: "get-starter-deso",
  templateUrl: "./get-starter-deso.component.html",
  styleUrls: ["./get-starter-deso.component.scss"],
})
export class GetStarterDeSoComponent {
  constructor() {}
}
